/*
Theme Name: Shoal Creek Conservancy Theme
Theme URI: http://creativepickle.com/
Description: Custom Theme for Shoal Creek Conservancy
Author: CreativePickle
Version: 1.0
Tags: 
*/

@import url('reset.css');
@import (less) "mixins.less";

/****LAYOUT****/
body > #container {
     flex: 1; /* 2 */
  width: 100%;
  position:relative;
  &:after{
      content: '\00a0'; /* &nbsp; */
    display: block;
    height: 0px;
    visibility: hidden;
      }
    }

body{
    background:#fff url(images/bg.png) repeat 0 0;
    display: flex;
  flex-direction: column;
  height: 100vh; /* 1, 3 */
    &.page-template-homepage.php{
        
    }
}

.tiled{
	background:url(images/topo-tile.png) repeat 0 0;
	}

.central{
    width:100%;
    max-width:@centralWidth!important;
    margin-left: auto;
    margin-right:auto;
    padding:0 2% 0 2%!important;
    position:relative;
    }


#main {
    width:@mainWidth;
	padding:0;
	margin:0px auto;
    }
    
#content{
    width:@mainWidth;
	padding:50px 0 30px 0;
	.bodyfont();
	&.no-pad{
	    padding:0;
	    }
	}

.subnav-bar{
	background:@aquaDark;
	ul.subnav-menu{
		.centered();
		width:100%;
		
		li{
			.inline-block();
			overflow:hidden;
			position:relative;
			margin:0 10px 0 10px;
			&.current_page_item a{color:@yellow!important;}
			a{
				display:block;
				padding:10px;
				color:#fff;
				background:@aquaDark;
				.uppercase();
				.montserrat(16px);
				letter-spacing: .03em;
				.transition(all linear 140ms);
				span{
					position:relative;
					z-index:5;
					}
				div.bg{
				display:block;
				position:absolute;
				left:50%;
				top:50%;
				margin-left:-5px;
				margin-top:-5px;
				width:10px;
				height:10px;
				background:@yellow;
				.scale(0);
				.rounded-corners(50%);
				.transition(all linear 200ms);
				z-index:1;
				}
				&:hover{
					color:@yellow!important;
					div.bg{
						.scale(30);
						}
					}
				}
			}
		}
	}
	
#content-wrap{
   
    }

#main-content{
	color:@content;
	padding:10px 40px 10px 10px;
	
    line-height:1.7;
    a{
    	color:@yellow;
    	&:hover{
    		color:@aqua;
    		}
    	}
    &.wide{
    	border:none;
    	padding:0!important;
    	}
    ul{
        padding:10px 0 10px 0;
        li{
            background:url(images/bullet.png) no-repeat 0 0px;
            padding:0 0 0 15px;
            margin-bottom:8px;
            position:relative;
            i.fa-circle{
            	.inline-block();
            	color:@yellow;
            	position:absolute;
            	font-size:8px;
            	top:6px;
            	left:0;
            	}
            }
        }
    ul.inline{
        li{
            display:inline-block;
            width:30%;
            }
        }
    ol{
	counter-reset: my-counter;
	padding-left:0!important;
    >li{
        position:relative;
        padding-left:40px!important;
        margin-bottom:20px;
        padding-top:5px;
        h2,h3,h4{margin-top:0!important;line-height:1!important;}
        &:before {
	content: counter(my-counter);
	counter-increment: my-counter;
	width:30px!important;
	height:30px!important;
	line-height:1!important;
	display:block;
	background:@aqua;
	color:#fff;
	.centered();
	.rounded-corners(50%);
    line-height:28px!important;
	position:absolute;
	.montserrat(12px);
	.bold();
	top:0;
	left:0;
}
        }
}
    img{
        border:2px solid @aquaDark;
        max-width:100%;
        height:auto;
        }
    h2{
    	.montserrat(34px);
    	color:@aqua;
    	.uppercase();
    	letter-spacing:.05em;
    	margin-bottom:25px;
      	}
    h3{
    	.montserrat(28px);
    	color:@yellow;
    	.uppercase();
    	letter-spacing:.05em;
    	font-weight:normal;
    	margin-bottom:25px;
      	}
    h4{
    	.bitter(24px);
    	color:@aqua;
    	letter-spacing:.05em;
    	font-weight:normal;
    	margin-bottom:25px;
    	.italic();
      	}
    
    }
	
#sidebar{
	padding:10px 10px 10px 40px;
	border-left:2px solid @grayLight;
	}
	
a.document-item{
	display: block;
	.centered();
	padding:15px 50px 15px 50px;
	background:@aquaDark url(images/watermark.svg) no-repeat 20px center;
	background-size:30px auto;
	color:@yellow;
	.montserrat(24px);
	letter-spacing: .05em;
	.uppercase();
	.transition();
	.scale(1);
	margin:0 0 20px 0;
	i{
		color:@aqua;
		margin-left:10px;
		.transition();
		}
	&:hover{
		.scale(1.05);
		color:#fff!important;
		background:@aquaMed url(images/watermark.svg) no-repeat 20px center;
		background-size:30px auto;
		i{color:@yellow;}
		}
	}
	
ul.subpage-menu{
    width:100%;
    padding:0;
    margin:0 0 50px 0;
    >li{
        display:block;
        margin:0;
        padding:0;
        >a{
            display:block;
            padding:10px;
            border-top: 2px solid @grayLight;
            .transition(all linear 130ms);
            .montserrat(18px);
            .uppercase();
            color:@aqua;
            .scale(1);
            position:relative;
            z-index:1;
            &:hover{
                background:@aquaDark;
                padding-left:15px;
                color:@yellow;
                border-top:2px solid @aquaDark;
                .scale(1.1);
                z-index:10;
                }
            }
            &.current_page_item>a, &.current_page_ancestor>a{
                background:#f1f1f1;
                padding-left:15px;
                }
            &:first-child>a{border-top:none;}
            >ul.children{display:none;}
            &.current_page_item>ul.children, &.current_page_ancestor>ul.children{
                padding:0;
                margin:0;
                display:block;
                >li{
                    >a{
                        .italic();
                        padding:10px;
                        border-top: 2px solid #e6e6e6;
                        .transition(all linear 130ms);
                        font-size:16px;
                        .text-shadow();
                        display:block;
                        &:hover{color:@accent;}
                        }
                         &:first-child>a{border-top:none;}
                         &.current_page_item>a{color:@accent;}
                    }
                }
        }
    }

ul.document-list{
     padding:0!important;
     margin:0px 0 10px 0!important;
    li{
        background:none!important;
        padding-left:0!important;
        margin-bottom:0!important;
        a{
            display:block;
            padding:10px 0 10px 0;
            .bodyfont();
            .uppercase();
            font-weight:300;
            border-bottom:1px solid @content;
            .transition();
            i{
                .inline-block();
                font-size:20px;
                color:@content;
                margin-right:10px;
                position:relative;
                top:2px;
                }
            &:hover{
                color:@content!important;
                background:@content;
                padding-left:10px;
                }
            }
        }
    }

#footer {
	position: relative;
	width:@mainWidth;
	clear:both;
	position:relative;
	.bg{
		overflow:hidden;
		img#footer-hills{
			width:100%;
			height:auto;
			display:block;
			}
		}

	} 
	
.footer-content{
	text-align:center;
	background:#155157;
	padding:0;
	.bitter(14px);
	padding-bottom:70px;
	h5{
		color:@aqua;
		.montserrat(23px);
		.uppercase();
		letter-spacing: .08em;
		margin-bottom:15px;
		}
	.left{
		text-align:left;
		color:#fff;
		line-height:1.5;
		padding:20px 40px 20px 0;
		span.yellow-text{
			.montserrat(16px);
			.uppercase();
			color:@yellow;
			letter-spacing:.05em;
			}
		}
	.right{
		padding:20px 0 20px 40px;
		border-left:3px solid #094a4f;
		ul.social-menu{
			li{
				color:@yellow;
				font-size:24px;
				&:hover{
					color:@aqua;
					}
				}
			}
		}
	}
	
.footer-content .gform_wrapper{
	
		padding:0!important;
		margin:0 0 25px 0!important;
		position:relative;
		.inline-block();
		width:80%!important;
		vertical-align:middle;
		margin-right:10px!important;
		 ::-webkit-input-placeholder {
                       color: #fff!important;
                    }
                    
                    :-moz-placeholder { /* Firefox 18- */
                       color: #fff!important;
                    }
                    
                    ::-moz-placeholder {  /* Firefox 19+ */
                       color: #fff!important;
                    }
                    
                    :-ms-input-placeholder {  
                       color: #fff!important;
                    }
		ul.gform_fields{
			li{
				margin-top:0!important;
				padding:0!important;
				}
			}
		label{display:none!important;}
		input[type=text]{
			border:2px solid @aqua!important;
			background:transparent;
			.rounded-corners(8px);
			color:#fff!important;
			.bitter(14px)!important;
			.italic();
			padding:8px 30px 8px 8px!important;
			width:100%!important;
			.transition();
			outline:none!important;
			&:focus{
				background:rgba(255, 255, 255, 0.2);
				}
			}
		.gform_footer{
			.inline-block();
			width:auto!important;
			position:absolute;
			top:15px;
			right:12px;
			padding:0!important;
			
			button{
				background:transparent;
				border:none!important;
				font-size:18px!important;
				outline:none;
				color:@yellow!important;
				.transition();
				.scale(1);
				.inline-block();
				padding:0!important;
				transform-origin:50% 50%;
				width:auto!important;
				i{display:block;}
				&:hover{
					color:@yellow;
					padding:0!important;
					cursor:pointer;
					background:transparent!important;
					.scale(1.3);
					}
				}
			}
		}
	
#copyright{
	.montserrat(12px);
	.uppercase();
	color:@aqua;
	letter-spacing: .18em;
	padding:20px 10px 20px 10px;
	a{
		color:@aqua;
		&:hover{
			color:@yellow;
			}
		}
	}

a#map{
	position:absolute;
		top:-260px;
		left:0;
		.centered();
	display:block;
	.transition();
	.scale(1);
	min-width:450px;
	>div{
		display:block;
		.montserrat(26px);
		.uppercase();
		color:@aqua;
		padding:10px 0 0 260px;
		line-height:1.2;
		span{
			display:block;
			.bitter(20px);
			.italic();
			text-transform:none;
			margin-bottom:4px;
			}
		}
	svg{
		.inline-block();
		position:absolute;
		left:0;
		top:0;
		width:330px;
		height:auto;
		.transition();
		.scale(1);
		}
	&:hover{
		>div{
			color:@yellow;
			}
		svg{.scale(1.1);}
		}
	}	

.section{
    width:100%;
    position:relative;
    padding:80px 0 80px 0;
    
    }

h5.section-head{
	.montserrat(34px);
	color:@aqua;
	.uppercase();
	font-weight: normal;
	margin-bottom:25px;
	letter-spacing: .06em;
	&.small{
		font-size:28px;
		margin-bottom:0;
		}
	&.white{color:#fff;}
	}
    
	
/**********PAGE STYLES**********/

.callout{
    display:block;
    .bitter(18px);
    position:relative;
    padding:0;
    text-align:left;
    background:@aqua url(images/svg/quote.svg) no-repeat 20px 20px;
    background-size:60px auto;
    padding:20px 20px 20px 100px;
    color:#fff;
    &.left{
        width:100%;
        max-width:340px;
        text-align:left;
        
        float:left;
        margin:0 20px 15px 0;
        }
    &.right{
        width:100%;
        max-width:340px;
        float:right;
        margin:0 0 15px 20px;
        }
    &.center{
        width:100%;
        border-top:1px solid #d9d8d3;
        border-bottom:1px solid #d9d8d3;
        margin:10px 0 10px 0;
      
        }
     }

.image-scaler{
    .scaler();
    min-width:100px;
    height:auto;
    }

/***********HEADER and NAVIGATION*********/
@menuLink: #666;
@menuLinkHover: #333;

#main-header{
    min-height:100px;
    background:transparent;
    border-top:3px solid @aqua;
    position:fixed;
    border-bottom:2px solid transparent;
    top:0;
    left:0;
    width:100%;
    padding:15px 0 25px 0;
    z-index:9999;
    .transition(all linear 120ms);
    #logo-wrap{
    	padding-top:15px;
    	}
    #logo-svg{
    	.inline-block();
    	width:80%;
    	.logo0,.logo1{
    		fill:#fff!important;
    		.transition();
    		}
    	}
    #nav-container{
    	text-align:right;
    	}
    &.active{
    	background:@aqua;
    	.box-shadow-custom(0,3px,3px,rgba(0,0,0,0.6));
    	border-bottom:2px solid #fff;
    	#logo-svg{
    		
    		}
    	ul#menu-top-navigation{
    		>li{
    			&:before{color:@yellow;}
    			>a{
    				color:#fff;
    				}
    			}
    		}
    	ul#menu-social-navigation{
    		li{
    			color:@yellow;
    			&:hover{
    				color:#fff;
    				}
    			}
    		}
    	ul#menu-main-navigation{
    		>li{
    			>a{
    				color:#fff;
    				}
    			}
    		}
    	}
}

#menu-main-navigation{
	margin-top:30px;
    list-style:none;
    padding:0;
    >li{
        .inline-block();
        margin-left:2px;
        position:relative;     
        &.current-menu-item a, &.current-page-ancestor a{
        	color:@aqua;
            	border:2px solid @yellow;
        	}
        >a{
            .montserrat(15px);
            letter-spacing:.02em;
            display:block;
            padding:5px 10px 5px 10px;
            color:#fff;
            border:2px solid transparent;
            .rounded-corners(6px);
            .transition();
            &:hover{
            	color:@aqua;
            	border:2px solid @yellow;
            	
            	}
            }
        }
    }

#menu-top-navigation{
	.inline-block();
	vertical-align:middle;
	margin-right:20px;
	>li{
		margin-right:12px;
		.transition();
		.scale(1);
		&:hover{
			.scale(1.1);
			}
		&:before{
			color:@aqua;
			margin-right:8px;
			}
		a{
			.montserrat(12px);
			.uppercase();
			color:#fff;
			.transition();
			&:hover{
				color:@yellow;
				}
		}
		}
	}

.social-menu{
    .inline-block();
    vertical-align:middle;
    margin:4px 0 0 0;
    padding:0;
    li{
        .inline-block();
        color:#fff;
        font-size:16px;
        padding:0;
        margin:0;
        position:relative;
        margin:0 3px 0 3px;
        color:@aqua;
        .scale(1);
        .transition();
        &:hover{
        	color:@yellow;
        	.scale(1.1);
        	}
        a{
            display:block;
            text-align:left;
            text-indent:-9999px;
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            }
        }
    }   	
/***********HEADER STYLES*******/

h1{
    color:#333;
    font-size:36px;
    padding:0 0 12px 0;
    }
    
h2{
    font-size:30px;
    color:#333;
    padding:0 0 12px 0;
    }
    
h3{
    font-size:24px;
    color:#333;
    padding:0 0 8px 0;
    }
    
h4{
    font-size:18px;
    padding:0 0 8px 0;
    }
    
/***********BUTTONS***********/

a.button, button.submit{
    .montserrat(18px);
    color:@aqua!important;
    font-weight:400;
    display:inline-block;
    background:transparent;
    padding:5px 20px 5px 20px;
    color:@content;
    position:relative;
    border:2px solid @yellow;
    .rounded-corners(6px);
    .centered();
    .scale(1);
    .transition();
    i{
    	margin-left:6px;
    	}
    &.white{
    	color:#fff!important;
    	}
    &:hover{
        background:@aqua!important;
        .scale(1.1);
        cursor:pointer;
        border:2px solid @aqua!important;
        color:#fff!important;
        }
    }
    
.buttons{
	.centered();
	a.button{
		margin:10px;
		}
	}

.small-button{
	.inline-block();
	.montserrat(16px);
	color:@aquaDark;
	margin-top:15px;
		i{
				.inline-block();
				width:20px;
				height:20px;
				position:relative;
				top:-1px;
				vertical-align: middle;
				background:@yellow;
				margin:0 0 0 8px;
				padding-left:2px;
				padding-top:1px;
				.rounded-corners(50%);
				.centered();
				line-height:20px;
				font-size:11px;
				color:#fff;
				.transition();
				.scale(1);
				}
			&:hover{
				color:@yellow;
				i{
					.scale(1.2);
					background:#fff;
					color:@aqua;
					}
				}
	}


/*********HOMEPAGE**********/
#video-banner{
	overflow:hidden!important;
	position:relative;
	height:100vh;
	.overlay{
        position:absolute;
        display:block;
        width:100%;
        z-index:10;
        height:100%;
        top:0;
        left:0;
        background:rgba(0, 0, 0, 0.7);
        }
        
video#banner-video { 
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url(images/banner.jpg) no-repeat;
    background-size: cover; 
}
	}

#banner-slides{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	.banner-slide-item{
		.fixed-bg();
		.scaler();
		width:100%;
		height:100vh;
		}
	button{
        	width:60px;
        	height:60px;
        	background:rgba(255,255,255,0.7);
        	.centered();
        	line-height:60px!important;;
        	color:#000;
        	border:none;
        	font-size:32px;
        	.rounded-corners(50%);
        	position:absolute;
        	z-index:2000;
        	.scale(1);
        	.transition();
        	padding:0;
        	outline:none;
        	i{
        		.inline-block();
        		vertical-align:middle;
        		position:relative;
        		}
        	&.slick-prev{
        		top:50%;
        		left:20px;
        		margin-top:-30px;
        		i{
        			top:-2px;
        			left:-2px;
        			}
        		}
        	&.slick-next{
        		top:50%;
        		right:20px;
        		margin-top:-30px;
        		i{
        			top:-2px;
        			right:-2px;
        			}
        		}
        	&:hover{
        		.scale(1.1);
        		background:rgba(255,255,255,0.9);
        		cursor:pointer;
        		}
        	}
	}

.overlay{
    	width:100%;
    	height:100%;
    	background:rgba(0,0,0,0.6);
    	z-index:10;
    	position: absolute;
    	top:0;
    	left:0;
    	}

#banner{
    border-bottom:1px solid #fff;
    position:relative;
    
    &.subpage{
        background:@accent;
        padding:170px 30px 80px 30px;
        border-bottom:1px solid #fff;
        h1{
            .montserrat(70px);
        color:@yellow;
        .uppercase();
        position:relative;
        letter-spacing:.05em;
        z-index:25;
        }
        }
    }
    
ul.slick-dots{
    width:100%;
    .centered();
    position:absolute;
    bottom:20px;
    z-index:5000;
    li{
        .inline-block();
        padding:0;
        max-width:14px;
        margin:0 3px 0 3px;
       svg{
       	width:100%;
       	height:auto;
       	#raindrop{
       		visibility:hidden;
       		}
       	}
       	&:hover{
       		cursor:pointer;
       		#circle{
       			fill:@aqua;
       			}
       		}
        &.slick-active{
            button{
                background:#fff!important;
                }
            }
        }
    }
    
.banner-content{
		.central();
		z-index:500;
        padding:20px;
        position:relative;
        .bold();
        color:@accent;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        .centered();
    .buttons a{opacity:0;}
    span.lead-in{
    	opacity:0;
        	display:block;
        	.bitter(22px);
        	color:#fff;
        	text-transform:none;
        	margin-bottom:20px;
        	.italic();
        	letter-spacing:.02em;
        	}
    h1{
        .uppercase();
        color:@aqua;
        line-height:1!important;
        margin-bottom:20px;
        .montserrat(80px);
        letter-spacing:.05em;
        padding:0 30px 0 30px;
        span{
        	color:@yellow;
        	display:block;
        	}
        
        }
    
    }
    
#announcements{
	color:#fff;
	padding:50px 0 50px 0;
	border-top:3px solid #fff;
	.announcement-item{
		padding-top:3px;
		.bitter(20px);
		a{
			color:#fff;
			.transition();
			i{
				.inline-block();
				width:30px;
				height:30px;
				position:relative;
				top:-2px;
				vertical-align: middle;
				background:@yellow;
				margin:0 0 0 8px;
				padding-left:2px;
				.rounded-corners(50%);
				.centered();
				line-height:30px;
				font-size:16px;
				color:#fff;
				.transition();
				.scale(1);
				}
			&:hover{
				color:@yellow;
				i{
					.scale(1.2);
					background:#fff;
					color:@aqua;
					}
				}
			}
		}
	}

a.block{
	display: block;
	width:100%;
	height:100%;position: absolute;
	top:0;left:0;
	z-index: 100;
	}

#programs{
	padding-bottom:0;

	#bg{
		width:100%;
		margin-top:80px;
		height:auto;
		overflow:hidden;
		position:relative;
		span.stripe{
			display:block;
			width:100%;
			position:absolute;
			z-index:7;
			background:@aqua;
			height:39px;
			left:0;
			bottom:0;
			}
		img#hills{
			.inline-block();
			width:100%;
			position:relative;
			z-index:10;
			}
		img#city{
			position:absolute;
			height:100%;
			width:auto;
			z-index:5;
			right:5%;
			bottom:0;
			}
		}
	}
	
	.program-item{
		position:relative;
		margin:20px;
		.inline-block();
		width:200px;
		color:@aquaDark;
		.bitter(22px);
		.italic();
		
		.icon{
			width:200px;
			height:200px;
			line-height:200px;
			.rounded-corners(50%);
			.transition();
			.scale(1);
			background:rgba(245, 245, 243, 1.000);
			border:2px solid #ededeb;
			margin-bottom: 20px;
			img{
				width:60%;
				height:auto;
				.inline-block();
				vertical-align: middle;
				border:none!important;
				}
			}
		&:hover{
			color:@yellow;
			.icon{
				.scale(1.1);
				background:rgba(245, 245, 243, 0.4);
				
				}
			}
		}
		
#events{
	background:@aqua;
	img#calendar{
		.inline-block();
		width:100px;
		margin-bottom:30px;
		}
	h5.section-head{
		color:#fff;
		line-height:1.2;
		.text-shadow-custom(2px,2px,0px,rgba(7, 140, 154, 1.000));
		}
	
	}
	
.event-item{
		.bitter(18px);
		border-bottom:2px solid @aquaLight!important;
		padding-left:110px;
		position: relative;
		color:#fff;
			min-height: 110px;
			padding-bottom:30px;
			margin-bottom:30px;
		h6{
			.montserrat(18px);
			letter-spacing:.06em;
			.uppercase();
			margin-bottom:10px;
			color:@aquaDark;
			}
		.date{
			.inline-block();
			width:80px;
			height:80px;
			color:#fff;.centered();
			padding-top:18px;
			.rounded-corners(50%);
			.box-shadow-custom(2px,2px,0px,rgba(7, 140, 154, 1.000));
			position: absolute;
			top:0;
			left:0;
		
			background:@yellow;
			.centered();
			.text-shadow-custom(2px,2px,0px,rgba(210, 170, 9, 1.000));
			span{
				display:block;
				line-height:1;
				letter-spacing:.06em;
				.uppercase();
				&.month{
					.montserrat(17px);
					}
				&.day{
					.montserrat(32px);
					}
				}
			}
		&.sidebar{
			.centered();
			color:@content;
			padding:10px 10px 40px 10px!important;
			h6{color:@aqua;}
			.date{
				position:relative;
				margin-bottom:25px;
				}
			}
		}
	
/********BLOG*******/

.blog-item{
	padding:0 20px 0 20px!important;
	color:@content;
	.item-content-wrap{
		background:#fff;
		border:1px solid #fff;
		.image{
			height:220px;
				border:4px solid @beige;
			}
		.item-content{
			padding:20px;
			background:@beige;
			line-height: 1.5;
			font-size:15px;
			h6{
				.montserrat(24px);
				color:@aqua;
				.uppercase();
				margin-bottom:20px;
				}
			
			a.readmore{
				display:none;
				}
			a.button{
				margin-top:15px;
				&:hover{
					color:#fff!important;
					i{color:@yellow!important;}
					}
				}
			
			}
	
		}
	&.subpage{
		padding:0;
		margin-bottom:30px;
		.item-content-wrap{
		background:#fff;
		border:1px solid #fff;
		.image{
			position:relative;
			height:auto;
			padding:25px;
			border:4px solid @beige;
			color:#fff;
			min-height:200px;
			.date-box{
				.inline-block();
				padding:7px;
				.centered();
				border:2px solid #fff;
				.rounded-corners(8px);
				position:relative;
				z-index: 10;
				span{
					display:block;
					line-height:1;
					&.month{
						.montserrat(20px);
						.uppercase();
						
						}
					&.day{
						.montserrat(26px);
						}
					}
				
				}
				h6{
					position:relative;
				z-index: 10;
				.montserrat(24px);
				.uppercase();
				padding:20px 0 0 0;
				line-height:1.2;
				}
				span.author{
					.bitter(20px);
					display:block;
					.italic();
					position:relative;
					z-index:10;
					a{
						color:#fff!important;
						&:hover{
							color:@yellow!important;
							}
						}
					}
			}
		.item-content{
			padding:20px;
			background:@beige;
			line-height: 1.5;
			font-size:15px;
			text-align:left;
			h6{
				.montserrat(24px);
				color:@aqua;
				.uppercase();
				margin-bottom:5px;
				}
				span.date{
				display:block;
				color:@aquaMed;
				.bitter(18px);
				margin-bottom:15px;
				}
			a.readmore{
				display:none;
				}
			a.button{
				margin-top:15px;
				&:hover{
					color:#fff!important;
					i{color:@yellow!important;}
					}
				}
			
			}
	
		}
		}
	
	&.sidebar{
		padding:0 10px 0 10px!important;
		.image{
			height:180px;
		}
		.item-content{
			background:#fff;
			padding:20px 0 20px 0;
			h6{
				color:@aqua;
				line-height:1.2;
				font-size:16px;
				a{
					color:@aqua;
					&:hover{
						color:@yellow;
						}
					}
				}
			}
		}
	}
	
/*********PROGRAM PAGES********/

.program-content{
	.bitter(26px);
	color:@aqua;
	.italic();
	.icon{
			float:left;
			width:200px;
			height:200px;
			line-height:200px;
			.rounded-corners(50%);
			.transition();
			.scale(1);
			background:rgba(245, 245, 243, 1.000);
			border:2px solid #ededeb;
			margin:0 40px 40px 0px;
			.centered();
			img{
				width:60%;
				height:auto;
				.inline-block();
				vertical-align: middle;
				border:none!important;
				}
			}
		.text{
			padding-top:20px;
			}
	}

.program-page-item{
	margin-bottom:30px;
	color:#fff;
	background:@aqua;
	position:relative;
	padding:20px 20px 20px 370px;
	line-height:1.7;
	a{
		color:@yellow;
		&:hover{
			color:@aquaDark!important;
			}
		}
	.title{
		width:350px;
		min-height:100%;
		position:absolute;
		top:0;
		left:0;
		padding:20px 80px 20px 20px;
		h5,.location{
			position:relative;
			z-index:10;
			}
		h5{
			.montserrat(20px);
			.uppercase();
			letter-spacing:.08em;
			line-height:1.3;
			}
		.location{
			display: block;
			.bitter(18px);
			.italic();
			padding-top:10px;
			margin-top:10px;
			border-top:1px solid @aqua;
			}
		}
	}

/***********BOARD & STAFF******/

#board-wrap{
	>.board-item{
		margin-bottom:80px;
		.item-content, .image-wrap{
			opacity:0;
			
			}
		.image-wrap{
			padding-right:40px;
			transform: translateY(20px);
			.image{
				border:2px solid @grayLight;
				width: 100%;
				padding-bottom: 100%;
				.rounded-corners(50%);
				transform: translateY(20px);
				}
			}
		.item-content{
			overflow:visible;
			padding:25px;
			color:#fff;
			background:@aqua;
			position:relative;
			transform: translateX(20px);
			min-height: 300px;
			.triangle{
				content:'';
				display:block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 50px 50px 50px 0;
				border-color: transparent #09a5b3 transparent transparent;
				position:absolute;
				left:-30px;
				top:120px;
				}
			h5{
				.bitter(24px);
				.italic();
				border-bottom:2px solid @aquaDark;
				margin-bottom:25px;
				padding-bottom:25px;
				}
			}
		&:nth-child(even){
			.image-wrap{
				float:right!important;
				padding-left:40px;
				padding-right:0;
				}
			.item-content{
				float:left!important;
				transform: translateX(-20px);
				background:@aquaMed;
				
				.triangle{
				content:'';
				display:block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 50px 0 50px 50px;
				border-color: transparent transparent transparent #10797e;
				position:absolute;
				left:~"calc(100% - 20px)";
				}
				}
			}
		}
	}

/************PROJECTS**********/

.project-item{
	position:relative;
	margin-bottom:40px!important;
	.title{
		background:@aqua;
		color:#fff;
		padding:25px 25px 25px 55px;
		.fader-toggle{
			.inline-block();
			background: transparent;
			color:#fff;
			font-size:30px;
			.rotate(0);
			border:none;
			position: absolute;
			top:20px;
			left:25px;
			outline:none;
			.transition(all ease-out 350ms);
			&:hover{
				cursor:pointer;
				}
			&.active{
				.rotate(90deg);
				}
			}
		h5{
			.montserrat(24px);
			.uppercase();
			letter-spacing: .08em;
			}
		.address{
			display:block;
			.montserrat(20px);
			letter-spacing: .05em;
			}
		}
	.project-content{
		position:relative;
		overflow:hidden;
	
		.text{
			color:#fff;
			.transition(all ease-out 350ms);
			transform:translateY(-100%);
			line-height:1.7;
			padding:35px;
			position:relative;
			background:rgba(0,0,0,0.6);
			&.active{
				transform:translateY(0);
				}
			a{
				color:@yellow;
				&:hover{
					color:@aqua;
					}
				}
			}
		.map-toggle{
			background:none;
			.montserrat(16px);
			color:@yellow!important;
			.inline-block();
			border:none;
			.scale(1);
			.transition();
			outline:none;
			&:hover{
				.scale(1.1);
				cursor:pointer;
				}
			i{
				margin-right:8px;
				}
			}
		}
	.map-wrap.project{
		width:100%;
		height:100%;
		position:absolute;
		top:0;
		left:0;
		.scale(0);
		.map-close{
			outline:none;
			.inline-block();
			position:absolute;
			z-index:500;
			top:7px;
			right:7px;
			border:none;
			background:@aqua;
			color:#fff;
			font-size:24px;
			height:36px;
			width:36px;
			.centered();
			line-height:32px!important;
			.rounded-corners(50%);
			.scale(1);
			.transition();
			&:hover{
				background:@yellow;
				cursor:pointer;
				.scale(1.1);
				}
			}
		.acf-map.project{
			height:100%;
			width:100%;
			margin:0;
			}
		}
	}

.acf-map{
	border:1px solid @grayLight;
	width:100%;
	&.page{
		height:400px;
		}
	}
/***********ABOUT PAGES********/

.about-item{
	border-bottom:2px solid @grayLight;
	.left{
		.centered();
		h5{
			.inline-block();
			max-width:200px;
			.centered();
			.bitter(24px);
			color:@aqua;
			.italic();
			}
		}
	.icon{
			.inline-block();
			width:200px;
			height:200px;
			line-height:200px;
			.rounded-corners(50%);
			.transition();
			.scale(1);
			background:rgba(245, 245, 243, 1.000);
			border:2px solid #ededeb;
			margin-bottom: 20px;
			img{
				width:60%;
				height:auto;
				.inline-block();
				vertical-align: middle;
				border:none!important;
				}
			}
	}
	
/*********TIMELINE*******/

#timeline-wrap{
	position:relative;
	#time-bar{
		background:@aquaDark;
		width:24px;
		height:100%;
		.rounded-corners(12px);
		position:absolute;
		left:50%;
		margin-left:-12px;
		}
	.timeline-year{
		
		>.year-item{
			
			.bitter(18px);
			color:#fff;
			position:relative;
			.connector{
				background:#b4b4b4;
				width:30%;
				position:absolute;
				top:50%;
				margin-top:-2px;
				height:4px;
				display:block;
				z-index:10;
				.opacity(0);
				}
			.year-dot{
					background:@yellow;
					width:30px;
					height:30px;
					border:3px solid #fff;
					.rounded-corners(50%);
					position:absolute;
					top:50%;
					left:50%;
					margin-top:-15px;
					margin-left:-15px;
					z-index:25;
					.scale(0);
					}
			&.year-header{
				.connector{
					width:15%;
					}
				.year-dot{
					background:@aqua;
					width:40px;
					height:40px;
					border:3px solid #fff;
					.rounded-corners(50%);
					position:absolute;
					top:50%;
					left:50%;
					margin-top:-20px;
					margin-left:-20px;
					z-index:25;
					}
				.number{
					.inline-block();
					width:170px;
					height:170px;
					border:3px solid @aqua;
					.centered();
					color:@yellow;
					background:#fff;
					.rounded-corners(50%);
					.montserrat(40px);
					padding-top:50px;
					}
				}
			.year-item-content{
				.opacity(0);
				.inline-block();
				width:auto;
				max-width:40%;
				padding:20px;
				position:relative;
				z-index:50;
				.text{
					text-align:left;
					line-height:1.3;
					a{.bold();color:@yellow;&:hover{text-decoration:underline;}}
					p{padding-bottom:5px;}
					}
				}
			img{border:none!important;max-width:100%;}
			&.light .year-item-content{background:@aqua;}
			&.medium .year-item-content{background:@aquaMed;}
			&.dark .year-item-content{background:@aquaDark;}
			}
		&.left{
			>.year-item{
				.year-item-content{
					.year-item-text{
						
						}
					}
				&.year-header{
					padding-left:20%;
					.connector{
					width:15%;
					left:35%!important;
					}
					}
				&:nth-child(odd){
					.year-item-content{transform: translateX(-30px);}
					.connector{transform: translateX(-50px);}
					text-align:left;
					span.connector{
						left:20%;
						}
					.year-item-text{
						
						}
					&.has-image{
						.image-wrap{float:left;width:25%;}
						.text{float:right;width:75%;padding-left:20px;}
						}
					}
				&:nth-child(even){
					text-align:right;
					.year-item-content{transform: translateX(30px);}
					.connector{transform: translateX(50px);}
					span.connector{
						left:50%;
						}
					.year-item-text{
						
						}
					&.has-image{
						.image-wrap{float:left;width:25%;}
						.text{float:right;width:75%;padding-left:20px;}
						}
					}
				}
			}
		&.right{
			>.year-item{
				.year-item-content{
					.year-item-text{
						
						}
					}
				&.year-header{
					padding-right:20%;
					}
				&:nth-child(even){
					text-align:left;
					.year-item-content{transform: translateX(-30px);}
					.connector{transform: translateX(-50px);}
					span.connector{
						left:20%;
						}
					.year-item-text{
						
						}
					&.has-image{
						.image-wrap{float:left;width:25%;}
						.text{float:right;width:75%;padding-left:20px;}
						}
					}
				&:nth-child(odd){
					text-align:right;
					.year-item-content{transform: translateX(30px);}
					.connector{transform: translateX(50px);}
					span.connector{
						left:50%;
						}
					.year-item-text{
						
						}
					&.has-image{
						.image-wrap{float:right;width:25%;}
						.text{float:left;width:75%;padding-right:20px;}
						}
					}
				}
			}
		}
	}
	
/*********AWARDS********/

#tickets{
	background:@grayLight;
	.gform_wrapper{
		input[type=text],textarea{
			&:focus{
				background:#fff!important;
				}
			}
		.gform_footer{.centered()!important;}
		}
	}
	
#gform_wrapper_11{
		input[type=text],textarea{
			&:focus{
				background:#fff!important;
				}
			}
	.gform_footer{.centered()!important;}
	.gfield_price{
		background:@grayLight!important;
		padding:20px!important;
		label{font-size:24px!important;}
		.ginput_product_price_label{
			font-size:24px!important;
			}
		.ginput_product_price{
			font-size:24px!important;
			color:@aquaDark
			}
		.gfield_description{
			ul>li{
				font-size:18px!important;
				.italic()!important;
				padding-left:22px!important;
				margin-bottom:12px;
				i.fa-circle{display:inline-block!important;top:10px;}
				}
			}
		}
	.gfield_total{
		background:transparent!important;
		padding:0!important;
		label{font-size:18px!important;}
		}
	}


#sponsorship{
	background:#f7f7f7;
	}

ul.logo-gallery{
	
	>li{
		i.fa-circle{display:none!important;}
		padding:0!important;
		width:300px;
		.inline-block();
		vertical-align: middle;
		margin:20px!important;
		.montserrat(22px);
		.uppercase();
		color:@aquaDark;
		img{
			max-width:90%;
			height:auto;
			.inline-block();
			border:none!important;
			}
		}
	}
/**********ITEMS and WIDGETS**************/

ul.subnav{
	.centered();
	li{
		i.fa-circle{display:none!important;}
		padding:0!important;
		margin:10px;
		.inline-block();
		a{
			display:block;
			padding:5px 15px 5px 15px;
			.montserrat(22px);
			.uppercase();
			color:@aqua;
			border:2px solid @grayLight;
			.rounded-corners(8px);
			.scale(1);
			.transition(all linear 180ms);
			&:hover{
				color:@yellow;
				background:@aquaDark;
				border:2px solid @aquaDark;
				.scale(1.1);
				}
			}
		}
	}


.item{
    width:100%;
    margin-bottom:50px;
    padding-bottom:50px;
    border-bottom:2px solid @grayLight;
}

.widget{
    margin-bottom:50px;
    h5.widget-title{
        .montserrat(30px);
        .uppercase();
        color:@aquaMed;
        letter-spacing: .05em;
        margin-bottom: 20px;
    }
}

ul.widget-list{
	>li{
		overflow:hidden;
		
		>a{
			display:block;
			.bitter(22px);
			color:@aquaDark;
			padding:10px 0 10px 0;
			border-bottom:1px solid @grayLight;
			.transition();
			&:hover,&.active{background:@aquaMed;padding:10px;color:@yellow;}
			}
		ul.sub-menu{
			height:0;
			>li{
					
				>a{
					display:block;
					.scale(0.9);
					opacity:0;
					padding:10px 0 10px 20px;
					color:@aqua;
					background:@grayLight;
					.bitter(18px);
					.italic();
					position:relative;
					&:hover{color:@aquaDark;}
					i.fa-circle{
						font-size:5px;
						color:@yellow;
						position:absolute;
						top:18px; left:10px;
						display:block;
						}
					}
				}
			}
		}
	}
	
/*****MAP***/

 .map-wrap-google {
        position: relative;
        padding-bottom: 75%; // This is the aspect ratio
        height: 0;
        overflow: hidden;
        margin-bottom:30px;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        border:2px solid @aquaMed;
        
    }
 }

/**********ACCORDIONS*******/
 
.toggler{
	margin-bottom:30px;
	.trigger{
		background:@aquaDark;
		position:relative;
		min-height:80px;
		padding:25px 20px 20px 80px;
		&:hover, &.active{
			cursor:pointer;
			background:@aquaMed;
			}
		svg.accordion-icon{
			display:block;
			width:40px;
			height:auto;
			position:absolute;
			top:10px;
			left:20px;
			.iconraindrop{visibility: hidden;}
			.iconplus{fill:@yellow;}
			.iconminus{fill:#fff;opacity:0;}
			}
		h5{
			color:#fff;
			.montserrat(24px);
			.uppercase();
			letter-spacing:.05em;
			line-height:1.3;
			}
		}
	.toggle_container{
		overflow:hidden;
		background:@grayLight;
		height:0;
		.block{
			opacity:0;
			padding:25px;
			}
		}
	}
/********ALIGNMENT********/

/* Alignment */
.alignleft {
    display: inline;
	float: left;
	margin-right: 1.625em;
}
.alignright {
	display: inline;
	float: right;
	margin-left: 1.625em;
}
.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft,
img.alignright,
img.aligncenter {
    margin-bottom: 1.625em;
}	

.clear{
    clear:both;
    }  

.wp-caption-text{
    font-size:12px;
    .italic();
    }
    
figure{
    -webkit-margin-before: 0;
-webkit-margin-after: 0;
-webkit-margin-start: 0;
-webkit-margin-end: 0;
    }
    
.fixed-bg{
    background-attachment:fixed!important;
    }
    
.scaler{
    position:relative;
    -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    -o-background-size: cover!important;
    background-size: cover!important;
}

.centered{
    text-align:center;
    }